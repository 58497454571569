import React from 'react';
import {IArrowProps} from './interfaces';
import './styles.scss';

const Arrows: React.FC<IArrowProps> = (
    {prevSlide, nextSlide}:IArrowProps,
):JSX.Element =>(
  <div className="arrows">
    <span className="prev" onClick={prevSlide}>
            &#10094;
    </span>
    <span className="next" onClick={nextSlide}>
            &#10094;
    </span>
  </div>
);

export default Arrows;
