import React from 'react';
import './App.scss';
import {BrowserRouter} from 'react-router-dom';
import RouterConfig from './Navigation/Routing';

const App:React.FC = (): JSX.Element => {
  return (
    <BrowserRouter>
      <RouterConfig />
    </BrowserRouter>
  );
};

export default App;
