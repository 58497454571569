/* eslint-disable */
import image1 from '../../assets/HouseImages/Cuartos/MediumRoom/Room16.jpg';
import image2 from '../../assets/HouseImages/Cuartos/MediumRoom/Room17.jpg';
import image3 from '../../assets/HouseImages/Cuartos/MediumRoom/Room18.jpg';
import image4 from '../../assets/HouseImages/Cuartos/MediumRoom/Room19.jpg';
import image5 from '../../assets/HouseImages/Cuartos/MediumRoom/Room20.jpg';
import image6 from '../../assets/HouseImages/Cuartos/MediumRoom/Room21.jpg';
import image7 from '../../assets/HouseImages/Cuartos/MediumRoom/Room22.jpg';
import image8 from '../../assets/HouseImages/Cuartos/MediumRoom/Room24.jpg';
import image9 from '../../assets/HouseImages/Cuartos/MediumRoom/Room25.jpg';
import image10 from '../../assets/HouseImages/Cuartos/MediumRoom/Room26.jpg';

interface ConstantsImages {
    [key: string]: {
        imgSrc: string
        alt: string
    }
}

export const ImageConstants: ConstantsImages = {
    img1: {
        alt: 'Stairs and hallway',
        imgSrc: image1,
    },
    img2: {
        alt: 'Dining Room and kitchen',
        imgSrc: image2,
    },
    img3: {
        alt: 'dining hall and social area',
        imgSrc: image3,
    },
    img4: {
        alt: 'room and social area',
        imgSrc: image4,
    },
    img5: {
        alt: 'stairs to second floor',
        imgSrc: image5,
    },
    img6: {
        alt: 'vintage social room',
        imgSrc: image6,
    },
    img7: {
        alt: 'Stairs and three doors',
        imgSrc: image7,
    },
    img8: {
        alt: 'Hallway to three rooms',
        imgSrc: image8,
    },
    img9: {
        alt: 'The courtyard with BBQ',
        imgSrc: image9,
    },
    img10: {
        alt: 'The Social area of the courtyard',
        imgSrc: image10,
    },
};
