import React from 'react';
import SectionHeader from '../Components/SectionHeader';
import {sectionTitle, cardsData} from './constants';

import './styles.scss';
import RoomCard from '../Components/RoomCard';
import RoutingConstants from '../../../../../Navigation/Constants';

const RoomsSections: React.FC = ():JSX.Element =>{
  console.log(RoutingConstants);
  return (
    <section
      className="rooms-container"
    >
      <SectionHeader/>
      <div
        className="room-card-section-container"
      >
        <h2 className="sub-section-title">{sectionTitle}</h2>
        <div
          className="room-card-container"
        >
          {
            Object.values(cardsData).map(
                (room) => (
                  <RoomCard
                    key={room.title}
                    title={room.title}
                    img={room.img}
                    description={room.description}
                    route={RoutingConstants[room.route].path}
                  />
                ),
            )
          }
        </div>
      </div>
    </section>
  );
};

export default RoomsSections;
