import React from 'react';
import {Link} from 'react-router-dom';
import {IRoomCardProps} from './interface';

import './styles.scss';

const RoomCard: React.FC<IRoomCardProps> = (
    {
      description, title, img, route = '/',
    }:IRoomCardProps,
):JSX.Element => (
  <div className="card">
    <div
      className="image-container"
      style={{borderRadius: '20px 20px 0 0', backgroundImage: `url(${img})`}}
    />
    <h3
      className="card-title"
    >
      {title}
    </h3>
    <p
      className="card-description"
    >
      {description}
    </p>
    <Link
      to={route}
      className="see-room-button"
    >
      {`Ver ${title}`}
    </Link>
  </div>
);

export default RoomCard;
