import React from 'react';

import {RoomHeaderConstants} from './Constants';

import './styles.scss';

const SectionHeader: React.FC = ():JSX.Element => {
  return (
    <div
      className="section-container"
    >
      {
        Object.values(RoomHeaderConstants).map(
            (item) => (
              <p
                className="paragraph"
                key={item.key}
              >
                {item.value}
              </p>
            ),
        )
      }
    </div>
  );
};

export default SectionHeader;
