import {IRoomCardProps} from '../Components/RoomCard/interface';
import BigRoom
  from '../../../../../assets/HouseImages/Cuartos/BigRoom/Room9.jpg';
import MediumRoom
  from '../../../../../assets/HouseImages/Cuartos/MediumRoom/Room25.jpg';
import SmallRoom
  from '../../../../../assets/HouseImages/Cuartos/SmallRoom/Room28.jpg';

export const sectionTitle: string = 'Tipos de Habitaciones';

interface RoomsCardInformation {
    [key:string]: IRoomCardProps
}

export const cardsData: RoomsCardInformation = {
  room1: {
    title: 'Habitación Doble',
    description: 'Habitación con cama doble, Baño privado, closet, ' +
        'un escritorio amplio, SmartTV' +
        ' y un sillón, la habitacion es completamente privada.' +
        ' Disponibles desde 25 a 30 metros cuadrados.',
    img: BigRoom,
    route: 'bigRoom',
  },
  room2: {
    title: 'Habitación Mediana',
    description: 'Habitación con cama sencilla, Baño privado, closet, ' +
        'un escritorio amplio y SmartTV.' +
        ' Disponibles desde 16 a 20 metros cuadrados.',
    img: MediumRoom,
    route: 'mediumRoom',

  },
  room3: {
    title: 'Habitación Sencilla',
    description: 'Habitación con cama sencilla, Baño privado, closet, ' +
        'un escritorio amplio y SmartTV.' +
        ' Disponibles de 15 metros cuadrados.',
    img: SmallRoom,
    route: 'smallRoom',
  },
};

