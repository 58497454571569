/* eslint-disable */
import image1 from '../../assets/HouseImages/Cuartos/BigRoom/Room2.jpg';
import image2 from '../../assets/HouseImages/Cuartos/BigRoom/Room3.jpg';
import image3 from '../../assets/HouseImages/Cuartos/BigRoom/Room4.jpg';
import image4 from '../../assets/HouseImages/Cuartos/BigRoom/Room5.jpg';
import image5 from '../../assets/HouseImages/Cuartos/BigRoom/Room6.jpg';
import image7 from '../../assets/HouseImages/Cuartos/BigRoom/Room8.jpg';
import image8 from '../../assets/HouseImages/Cuartos/BigRoom/Room9.jpg';
import image9 from '../../assets/HouseImages/Cuartos/BigRoom/Room10.jpg';

interface ConstantsImages {
    [key: string]: {
        imgSrc: string
        alt: string
    }
}

export const ImageConstants: ConstantsImages = {
    img1: {
        alt: 'Stairs and hallway',
        imgSrc: image1,
    },
    img2: {
        alt: 'Dining Room and kitchen',
        imgSrc: image2,
    },
    img3: {
        alt: 'dining hall and social area',
        imgSrc: image3,
    },
    img4: {
        alt: 'room and social area',
        imgSrc: image4,
    },
    img5: {
        alt: 'stairs to second floor',
        imgSrc: image5,
    },
    img7: {
        alt: 'Stairs and three doors',
        imgSrc: image7,
    },
    img8: {
        alt: 'Hallway to three rooms',
        imgSrc: image8,
    },
    img9: {
        alt: 'The courtyard with BBQ',
        imgSrc: image9,
    },
};
