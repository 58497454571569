import React from 'react';
import SliderContentImages from '../Constants';
import {ISliderContentProps} from './interface';
import SliderHeader from '../SliderHeader';
import {AnimatePresence, motion} from 'framer-motion';
import './styles.scss';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const Slide: React.FC<ISliderContentProps> = (
    {activeIndex}:ISliderContentProps,
): JSX.Element =>{
  const sliderElements = SliderContentImages.map((slide, index) => {
    return <motion.div
      className={`slides ${slide.className}`}
      style={{
        backgroundImage: `url(${slide.backgroundImage})`,
        opacity: 0.8,
      }}
      key={index}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: {type: 'spring', stiffness: 300, damping: 30},
        opacity: {duration: 0.5},
      }}
    >
      <SliderHeader
        altText={slide.altText}
        headerString={slide.title}
        imgSrc={slide.logoImage}
      />
      <div className="sub-titles-container">
        <h5 className={`text-${slide.className}`}>{slide.text}</h5>
        <h3 className={`text-${slide.className}`}>{slide.subTitle}</h3>
      </div>
      <div className="background-blur"/>
    </motion.div>;
  });


  return (
    <section>
      <AnimatePresence initial={false}>
        {sliderElements[activeIndex]}
      </AnimatePresence>,
    </section>
  );
};
export default Slide;
